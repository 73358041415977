import React, { Component } from 'react';
import PortalButtonBar from '../PortalButtonBar';
import UrlaubsantragWocheTable from './Edit/UrlaubsantragWocheTable';
import UrlaubsantragProjekte from './Edit/UrlaubsantragProjekte';
import UrlaubsantragTagRow from './Edit/UrlaubsantragTagRow';
import UrlaubsantragKommentare from './Edit/UrlaubsantragKommentare';
import UrlaubsantragUebersicht from './Edit/UrlaubsantragUebersicht';
import UrlaubsantragGenehmigungsVerantwortliche from './Edit/UrlaubsantragGenehmigungsVerantwortliche';
import UrlaubsantragViewWochen from './Widget/UrlaubsantragViewWochen';
import 'react-dates/initialize';
import moment from 'moment';
import * as api from '../api';
import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { URLAUBSANTRAG_STATUS } from './constants';
import toast from '../toastify';
import uuid4 from 'uuid/v4';
import GeschaeftsfuehrungInvolviertAlert from './Edit/GeschaeftsfuehrungInvolviertAlert';
import ReststundenAlert from './Edit/ReststundenAlert';
import numeral from 'numeral';

const formatHours = value => `${numeral(value).format('0.00')} h`;

/**
 * Type for representing the Urlaubsantrag. It is designed to facilitate easy serialization/deserialization vs the backend and is thus structured according to
 * what the backend expects and delivers. Any changes to properties not marked for internal use on the frontend must be coordinated with the backend.
 *
 * @typedef {Object} Urlaubsantrag
 * @property {UrlaubsantragId} urlaubsantragId
 * @property {string} von - in YYYY-MM-DD format
 * @property {string} bis - in YYYY-MM-DD format
 * @property {string} status
 * @property {string} kommentar
 * @property {UrlaubsantragTagKonditionen[]} konditionen
 * @property {UrlaubsantragProjektVertretungsDaten[]} projektVertretungsDaten
 * @property {UrlaubsantragGenehmigungsDaten[]} genehmigungsDaten
 */

/**
 * @typedef {Object} UrlaubsantragId
 * @property {string} id - uuid of the Urlaubsantrag
 */

/**
 * @typedef {Object} UrlaubsantragTagKonditionen
 * @property {Moment} date - Moment representation of the tag property; required for use here on the frontend
 * @property {string} tag - YYYY-MM-DD
 * @property {float} sollstunden
 * @property {float} urlaubstunden
 * @property {float} ueberstunden
 */

/**
 * @typedef {Object} UrlaubsantragProjektVertretungsDaten
 * @property {int} projektId
 * @property {int} vertretungId
 * @property {int} value - Projekt id, for internal use with React-Select
 * @property {string} label - Projekt name, for internal use with React-Select
 * @property {boolean} selected - is the Projekt currently included in the state.urlaubsantrag.projektVertretungsDaten, for internal use with React-Select
 * @property {string} vertretungName - name of the Vertreter for this Projekt
 */

/**
 * @typedef {Object} UrlaubsantragGenehmigungsDaten
 * @property {int} verantwortlicherId - id of the Genehmigungsverantwortlicher Mitarbeiter
 * @property {int} value - id of the Genehmigungsverantwortlicher Mitarbeiter, for internal use with React-Select
 * @property {string} label - name of the Genehmigungsverantwortlicher Mitarbeiter, for internal use with React-Select
 */

export default class UrlaubsantragEdit extends Component {
    constructor(props) {
        super(props);
        this.urlaubsAntragEditor = React.createRef();
        this.urlaubsantragProjekteEditor = React.createRef();
        this.urlaubsantragGenehmigungsVerantwortlicheEditor = React.createRef();

        /**
         * @type {{
         *  urlaubsantrag: Urlaubsantrag,
         *  projektsForSelect: UrlaubsantragProjektVertretungsDaten[],
         *  mitarbeitersForSelect: UrlaubsantragGenehmigungsDaten[],
         *  urlaubsantragIsDirty: boolean,
         *  saveInProgress: boolean,
         *  beantragenInProgress: boolean,
         *  verwerfenInProgress: boolean,
         *  stornierenInProgress: boolean
         * }}
         */
        this.state = {
            mitarbeitersForSelect: [],
            projektsForSelect: [],
            resizeDatePicker: false,
            startDate: null,
            endDate: null,
            focusedInput: null,
            showUrlaubsAntragEditor: false,
            urlaubsantrag: {
                urlaubsantragId: { id: null },
                von: null,
                bis: null,
                status: null,
                kommentar: '',
                konditionen: [],
                projektVertretungsDaten: [],
                genehmigungsDaten: [],
            },
            zeiterfassungStats: {},
            fetching: true,
            urlaubsantragIsDirty: false,
            saveInProgress: false,
            beantragenInProgress: false,
            verwerfenInProgress: false,
            stornierenInProgress: false,
            stundenPristine: true,
            averageDailyWorkHours: null,
            darfStornieren: false,
        };
    }

    componentDidMount() {
        this.updateDatePickerView();
        window.addEventListener('resize', this.updateDatePickerView);

        const pathFragments = location.pathname.split('/');
        if (pathFragments.length === 4 && pathFragments[3].length === 36) {
            this.loadExistingUrlaubsantragData(pathFragments[3]);
        } else {
            this.loadNewUrlaubsantragData();
        }
    }

    loadExistingUrlaubsantragData = async uuid => {
        this.setState({
            fetching: true,
        });
        const json = await api.getJson(`/urlaub/urlaubsantrag/existing-data/${uuid}`);

        this.setState({
            startDate: moment(json.urlaubsantrag.von),
            endDate: moment(json.urlaubsantrag.bis),
            urlaubsantrag: this.hydrateUrlaubsantrag(json.urlaubsantrag),
            mitarbeitersForSelect: json.mitarbeiters,
            projektsForSelect: json.projekts,
            zeiterfassungStats: json.zeiterfassungStats,
            darfStornieren: json.darfStornieren,
            fetching: false,
        });
        this.calculate();
    };

    /**
     * Hydrates an urlaubsantrag received from the backend with additional properties which are used only on the frontend.
     *
     * @param {Urlaubsantrag} urlaubsantrag - the urlaubsantrag object as received from the backend, without any fields for internal use in the frontend
     * @return {Urlaubsantrag}
     */
    hydrateUrlaubsantrag = urlaubsantrag => {
        urlaubsantrag.konditionen.forEach(kondition => {
            kondition.date = moment(kondition.tag);
        });
        urlaubsantrag.projektVertretungsDaten.forEach(vertretungsDatum => {
            vertretungsDatum.frontendUuid = uuid4();
        });

        return urlaubsantrag;
    };

    loadNewUrlaubsantragData = async () => {
        this.setState({
            fetching: true,
        });
        const json = await api.getJson(`/urlaub/urlaubsantrag/new-data/ich`);
        const urlaubsantrag = this.state.urlaubsantrag;
        urlaubsantrag.projektVertretungsDaten = json.projekts
            .filter(projekt => projekt.current === true)
            .map(projekt => this.getNewProjektVertretungsDaten(projekt));
        this.setState({ mitarbeitersForSelect: json.mitarbeiters, projektsForSelect: json.projekts, urlaubsantrag, fetching: false });
    };

    getNewProjektVertretungsDaten = projekt => {
        return {
            projektId: projekt.value,
            vertretungId: null,
            value: projekt.value,
            label: projekt.label,
            vertretungName: '',
            frontendUuid: uuid4(),
        };
    };

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDatePickerView);
    }

    updateDatePickerView = () => {
        this.setState({
            resizeDatePicker: window.innerWidth < 1200,
        });
    };

    addProjektVertretung = () => {
        let projektToAdd = this.state.projektsForSelect.find(projekt => projekt.current === true);
        if (projektToAdd === undefined) {
            projektToAdd = this.state.projektsForSelect.find(projekt => true);
        }
        const urlaubsantrag = this.state.urlaubsantrag;
        urlaubsantrag.projektVertretungsDaten.push(this.getNewProjektVertretungsDaten(projektToAdd));
        this.setState({ urlaubsantrag, urlaubsantragIsDirty: true });
    };

    removeProjektVertretung = projektVertretungToRemove => {
        const indexOfProjektToRemove = this.state.urlaubsantrag.projektVertretungsDaten.findIndex(
            projektVertretung => projektVertretung.frontendUuid === projektVertretungToRemove.frontendUuid
        );
        const urlaubsantrag = this.state.urlaubsantrag;
        urlaubsantrag.projektVertretungsDaten.splice(indexOfProjektToRemove, 1);
        this.setState({ urlaubsantrag, urlaubsantragIsDirty: true });
    };

    onProjektVertretungProjectChange = (projektVertretung, newProjekt) => {
        projektVertretung.projektId = newProjekt.value;
        projektVertretung.value = newProjekt.value;
        projektVertretung.label = newProjekt.label;

        // urlaubsantrag has to be set in state because it contains projektVertretung
        this.setState({ urlaubsantrag: this.state.urlaubsantrag, urlaubsantragIsDirty: true });
    };

    onProjektVertretungVertretungChange = (projektVertretung, newVertretung) => {
        projektVertretung.vertretungId = newVertretung.value;
        projektVertretung.vertretungName = newVertretung.label;
        // urlaubsantrag has to be set in state because it contains projektVertretung
        this.setState({ urlaubsantrag: this.state.urlaubsantrag, urlaubsantragIsDirty: true });
    };

    areProjektVertretungenValid = () => {
        return this.state.urlaubsantrag.projektVertretungsDaten.every(projektVertretung => projektVertretung.vertretungId !== null);
    };

    toggleUrlaubsAntragEditor = () => {
        const urlaubsAntragEditor = this.urlaubsAntragEditor.current;
        if (this.state.showUrlaubsAntragEditor === false) {
            this.setState({ showUrlaubsAntragEditor: true });
        } else {
            this.setState({ showUrlaubsAntragEditor: false });
        }
        setTimeout(() => urlaubsAntragEditor.scrollIntoView({ block: 'end', behavior: 'smooth' }), 50);
    };

    onDatesChange = ({ startDate, endDate }) => {
        const updateState = (startDate, endDate, data = null) => {
            let { urlaubsantrag, zeiterfassungStats } = this.state;
            let urlaubsantragIsDirty = false;
            let averageDailyWorkHours = null;
            if (data !== null) {
                urlaubsantrag.von = startDate.format('YYYY-MM-DD');
                urlaubsantrag.bis = endDate.format('YYYY-MM-DD');
                urlaubsantrag.konditionen = data.zeitraumDaten.tagStunden.map(data => {
                    return {
                        date: moment(data.tag),
                        tag: moment(data.tag).format('YYYY-MM-DD'),
                        sollstunden: data.sollstunden,
                        urlaubstunden: data.sollstunden,
                        ueberstunden: 0,
                    };
                });
                zeiterfassungStats = data.zeiterfassungStats;
                urlaubsantragIsDirty = true;
                averageDailyWorkHours = data.averageDailyWorkHours;
            }

            this.setState({ startDate, endDate, urlaubsantrag, zeiterfassungStats, urlaubsantragIsDirty, averageDailyWorkHours });
            this.calculate();
        };

        if (startDate !== null && endDate !== null) {
            this.getZeitraumData(startDate, endDate)
                .then(data => {
                    if (data.zeitraumDaten) {
                        updateState(startDate, endDate, data);
                    } else {
                        let errorMessage = 'Ein Fehler ist aufgetreten.';
                        if (data.error !== undefined) {
                            errorMessage = data.error;
                        }
                        toast.error(errorMessage + ' Der Urlaubsantrag kann nicht bearbeitet werden.');
                    }
                })
                .catch(data => {
                    toast.error('Ein Fehler ist aufgetreten. Der Urlaubsantrag kann nicht bearbeitet werden.');
                });
        } else {
            updateState(startDate, endDate);
        }
    };

    getZeitraumData = async (startDate, endDate) => {
        const start = startDate.format('YYYY-MM-DD');
        const end = endDate.format('YYYY-MM-DD');

        return await api.getJson(`/urlaub/urlaubsantrag/zeitraum-daten/ich/${start}/${end}`);
    };

    calculate = () => {
        const { urlaubsantrag, zeiterfassungStats } = this.state;
        const urlaubStdSumme =
            urlaubsantrag.konditionen.length > 0 ? urlaubsantrag.konditionen.reduce((carry, urlaubStdSumme) => carry + urlaubStdSumme.urlaubstunden, 0) : 0;
        const ueberStdSumme =
            urlaubsantrag.konditionen.length > 0 ? urlaubsantrag.konditionen.reduce((carry, ueberStdSumme) => carry + ueberStdSumme.ueberstunden, 0) : 0;

        return {
            verfuegbarUrlaubStd: zeiterfassungStats.urlaubAvailableStunden,
            verfuegbarUeberStd: zeiterfassungStats.ueberStunden,
            aktuellerAntragUrlaubStd: urlaubStdSumme,
            aktuellerAntragUeberStd: ueberStdSumme,
            restUrlaubStd: zeiterfassungStats.urlaubAvailableStunden - urlaubStdSumme,
            restUeberStd: zeiterfassungStats.ueberStunden - ueberStdSumme,
        };
    };

    setUrlaubsantragTagRow = (tag, { urlaubStd, ueberStd }) => {
        this.state.urlaubsantrag.konditionen.find(element => element.tag === tag).urlaubstunden = urlaubStd;
        this.state.urlaubsantrag.konditionen.find(element => element.tag === tag).ueberstunden = ueberStd;
        this.state.urlaubsantragIsDirty = true;
        this.setState(this.state);
    };

    setKommentar = kommentar => {
        const urlaubsantrag = this.state.urlaubsantrag;
        urlaubsantrag.kommentar = kommentar;
        this.setState({ urlaubsantrag, urlaubsantragIsDirty: true });
    };

    areKonditionenValid = () => {
        const konditionen = this.state.urlaubsantrag.konditionen;

        if (konditionen.length < 1) {
            return false;
        }

        const urlaubsstunden = konditionen.reduce((acc, cur) => acc + cur.urlaubstunden, 0);

        if (konditionen && 0 === konditionen[0].ueberstunden && 0 === urlaubsstunden) {
            toast.error('Ein Urlaubsantrag darf nicht ohne gebuchte Stunden abgegeben werden.');
            return false;
        }

        return true;
    };

    saveDraft = async () => {
        if (!this.isUrlaubsantragValid()) {
            this.markAllFieldsNotPristine();
            return;
        }

        this.setState({ saveInProgress: true });

        let json;
        try {
            json = await api.postJson(`/urlaub/urlaubsantrag/save/ich/${this.state.urlaubsantrag.urlaubsantragId.id}`, this.state.urlaubsantrag);
        } catch (e) {
            toast.error('Ein Fehler ist aufgetreten');
            this.setState({ saveInProgress: false });
            throw e;
        }

        if (json.urlaubsantrag !== undefined) {
            toast.success('Urlaubsantrag erfolgreich als Entwurf gespeichert');
            this.setState({
                urlaubsantrag: this.hydrateUrlaubsantrag(json.urlaubsantrag),
                urlaubsantragIsDirty: false,
                saveInProgress: false,
            });
        } else {
            let errorMessage = 'Ein Fehler ist aufgetreten. Der Urlaubsantrag konnte nicht als Entwurf gespechiert werden.';
            if (json.error !== undefined) {
                errorMessage = json.error;
            }
            toast.error(errorMessage);
            this.setState({ saveInProgress: false });
            throw errorMessage;
        }
    };

    beantragen = async () => {
        if (!this.isUrlaubsantragValid()) {
            this.markAllFieldsNotPristine();
            return;
        }

        const doBeantragen = () => {
            this.setState({ beantragenInProgress: true });
            api.getJson(`/urlaub/urlaubsantrag/beantragen/${this.state.urlaubsantrag.urlaubsantragId.id}`)
                .then(json => {
                    if (json.urlaubsantrag !== undefined) {
                        toast.success('Urlaubsantrag erfolgreich beantragt');
                        if (this.state.showUrlaubsAntragEditor) {
                            this.toggleUrlaubsAntragEditor();
                        }
                        this.setState({
                            urlaubsantrag: this.hydrateUrlaubsantrag(json.urlaubsantrag),
                            urlaubsantragIsDirty: false,
                            beantragenInProgress: false,
                        });
                    } else {
                        let errorMessage = 'Ein Fehler ist aufgetreten';
                        if (json.error !== undefined) {
                            errorMessage = json.error;
                        }
                        toast.error(errorMessage + '. Der Urlaubsantrag konnte nicht beantragt werden.');
                        this.setState({ beantragenInProgress: false });
                    }
                })
                .catch(json => {
                    toast.error('Ein Fehler ist aufgetreten. Der Urlaubsantrag konnte nicht beantragt werden.');
                    this.setState({ beantragenInProgress: false });
                });
        };
        if (this.state.urlaubsantragIsDirty) {
            this.saveDraft().then(savePromise => {
                doBeantragen();
            });
        } else {
            doBeantragen();
        }
    };

    verwerfen = () => {
        if (this.state.urlaubsantrag.urlaubsantragId.id === null) {
            location = '/';
            return;
        }

        this.setState({ verwerfenInProgress: true });
        api.getJson(`/urlaub/urlaubsantrag/verwerfen/${this.state.urlaubsantrag.urlaubsantragId.id}`)
            .then(json => {
                if (json.urlaubsantrag !== undefined) {
                    toast.success('Urlaubsantrag erfolgreich verworfen');
                    this.setState({ urlaubsantrag: this.hydrateUrlaubsantrag(json.urlaubsantrag), urlaubsantragIsDirty: false, verwerfenInProgress: false });
                } else {
                    let errorMessage = 'Ein Fehler ist aufgetreten';
                    if (json.error !== undefined) {
                        errorMessage = json.error;
                    }
                    toast.error(errorMessage + '. Der Urlaubsantrag konnte nicht verworfen werden.');
                    this.setState({ verwerfenInProgress: false });
                }
            })
            .catch(json => {
                toast.error('Ein Fehler ist aufgetreten. Der Urlaubsantrag konnte nicht verworfen werden.');
                this.setState({ verwerfenInProgress: false });
            });
    };

    stornieren = () => {
        this.setState({ stornierenInProgress: true });
        api.getJson(`/urlaub/urlaubsantrag/stornieren/${this.state.urlaubsantrag.urlaubsantragId.id}`)
            .then(json => {
                if (json.urlaubsantrag !== undefined) {
                    toast.success('Urlaubsantrag erfolgreich storniert');
                    this.setState({ urlaubsantrag: this.hydrateUrlaubsantrag(json.urlaubsantrag), urlaubsantragIsDirty: false, stornierenInProgress: false });
                } else {
                    let errorMessage = 'Ein Fehler ist aufgetreten';
                    if (json.error !== undefined) {
                        errorMessage = json.error;
                    }
                    toast.error(errorMessage + '. Der Urlaubsantrag konnte nicht storniert werden.');
                    this.setState({ stornierenInProgress: false });
                }
            })
            .catch(json => {
                toast.error('Ein Fehler ist aufgetreten. Der Urlaubsantrag konnte nicht storniert werden.');
                this.setState({ stornierenInProgress: false });
            });
    };

    onGenehmigungsverantwortlicherChange = values => {
        const urlaubsantrag = this.state.urlaubsantrag;
        urlaubsantrag.genehmigungsDaten = values;
        this.setState({ urlaubsantrag, urlaubsantragIsDirty: true });
    };

    isZeitraumSelected = () => this.state.urlaubsantrag.von && this.state.urlaubsantrag.bis;

    areGenehmigungsValid = () => this.state.urlaubsantrag.genehmigungsDaten.length > 0;

    isUrlaubsantragValid = () => this.areProjektVertretungenValid() && this.areGenehmigungsValid() && this.areKonditionenValid();

    beantragterUrlaubWeeks = () => {
        const beantragterUrlaubWeeks = [[]];

        let i = 0;
        this.state.urlaubsantrag.konditionen.forEach((tag, j) => {
            if (0 !== j && 0 === tag.date.weekday()) {
                i++;
                beantragterUrlaubWeeks[i] = [];
            }
            beantragterUrlaubWeeks[i].push(tag);
        });

        return beantragterUrlaubWeeks;
    };

    canUrlaubsantragBeSaved = () => {
        return this.isUrlaubsantragEntwurf() && !this.isRequestInProgress();
    };

    isUrlaubsantragEntwurf = () => {
        return this.state.urlaubsantrag.status === URLAUBSANTRAG_STATUS.ENTWURF || this.state.urlaubsantrag.urlaubsantragId.id === null;
    };

    isRequestInProgress = () => {
        return this.state.saveInProgress || this.state.beantragenInProgress || this.state.verwerfenInProgress || this.state.stornierenInProgress;
    };

    canUrlaubsantragBeBeantragt = () => {
        return this.canUrlaubsantragBeSaved();
    };

    canUrlaubsantragBeVerworfen = () => {
        return (
            (([URLAUBSANTRAG_STATUS.ENTWURF, URLAUBSANTRAG_STATUS.BEANTRAGT].includes(this.state.urlaubsantrag.status) &&
                this.state.urlaubsantrag.urlaubsantragId.id !== null) ||
                this.state.urlaubsantrag.urlaubsantragId.id === null) &&
            !this.isRequestInProgress()
        );
    };

    canUrlaubsantragBeStorniert = () => {
        return this.state.urlaubsantrag.status === URLAUBSANTRAG_STATUS.GENEHMIGT && !this.isRequestInProgress() && this.state.darfStornieren;
    };

    markStundenNotPristine = () => {
        this.setState({ stundenPristine: false });
    };

    markAllFieldsNotPristine = () => {
        this.urlaubsantragProjekteEditor.current.markAllNotPristine();
        this.urlaubsantragGenehmigungsVerantwortlicheEditor.current.markNotPristine();
        this.markStundenNotPristine();
    };

    render() {
        const stundenResult = this.calculate();
        const showUrlaubsAntragEditor = this.state.showUrlaubsAntragEditor;
        const resizeDatePicker = this.state.resizeDatePicker;
        const beantragterUrlaubWeeks = this.beantragterUrlaubWeeks();

        if (this.state.fetching) {
            return (
                <div>
                    <div>
                        <i className="ml-2 fas fa-2x fa-spinner fa-pulse" />
                    </div>
                    <span>Loading ...</span>
                </div>
            );
        }

        return (
            <div>
                <PortalButtonBar>
                    <div className="form-group row align-items-center mb-0">
                        <span className="col-sm-12 col-md-2">Bitte gewünschten Urlaubszeitraum auswählen</span>
                        <div className="col-md-6 col-sm-12">
                            <DateRangePicker
                                startDate={this.state.startDate}
                                startDateId="your_unique_start_date_id"
                                endDate={this.state.endDate}
                                endDateId="your_unique_end_date_id"
                                onDatesChange={this.onDatesChange}
                                focusedInput={this.state.focusedInput}
                                onFocusChange={focusedInput => this.setState({ focusedInput })}
                                noBorder={false}
                                hideKeyboardShortcutsPanel={true}
                                startDatePlaceholderText="Von"
                                endDatePlaceholderText="Bis"
                                showClearDates={true}
                                minimumNights={0}
                                orientation={resizeDatePicker ? 'vertical' : 'horizontal'}
                                disabled={!this.canUrlaubsantragBeSaved()}
                            />
                        </div>
                    </div>
                </PortalButtonBar>
                {this.isZeitraumSelected() && (
                    <React.Fragment>
                        {this.isUrlaubsantragEntwurf() && (
                            <UrlaubsantragUebersicht
                                {...stundenResult}
                                isUrlaubsDateSet={this.state.startDate && this.state.endDate}
                                toggleUrlaubsAntrag={this.toggleUrlaubsAntragEditor}
                            />
                        )}
                        {!this.isUrlaubsantragEntwurf() && <UrlaubsantragViewWochen urlaubsantrag={this.state.urlaubsantrag} />}
                        <GeschaeftsfuehrungInvolviertAlert urlaubsantrag={this.state.urlaubsantrag} dailyWorkHours={this.state.averageDailyWorkHours} />
                        <ReststundenAlert
                            enableAlert={!this.state.stundenPristine}
                            restUrlaubStd={stundenResult.restUrlaubStd}
                            restUeberStd={stundenResult.restUeberStd}
                            aktuellerAntragUeberStd={stundenResult.aktuellerAntragUeberStd}
                        />
                        {this.state.startDate && this.state.endDate ? (
                            <div ref={this.urlaubsAntragEditor} className={`card table-responsive-xl mt-3 ${showUrlaubsAntragEditor ? `d-block` : `d-none`}`}>
                                <div className="card-body">
                                    <div className="row">
                                        {beantragterUrlaubWeeks.map((week, i) => (
                                            <div className="col-xxl" key={i}>
                                                <UrlaubsantragWocheTable>
                                                    <div style={i === 0 && beantragterUrlaubWeeks.length > 1 ? { marginTop: 63 * (7 - week.length) } : {}} />
                                                    {week.map(date => (
                                                        <React.Fragment key={date.date}>
                                                            <UrlaubsantragTagRow
                                                                value={date}
                                                                setValue={row => this.setUrlaubsantragTagRow(date.tag, row)}
                                                                sollStd={date.sollstunden}
                                                                date={date.date}
                                                                onBlur={this.markStundenNotPristine}
                                                            />
                                                        </React.Fragment>
                                                    ))}
                                                </UrlaubsantragWocheTable>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ) : null}
                        <UrlaubsantragProjekte
                            ref={this.urlaubsantragProjekteEditor}
                            values={this.state.urlaubsantrag.projektVertretungsDaten}
                            availableProjects={this.state.projektsForSelect}
                            availableVertretungs={this.state.mitarbeitersForSelect}
                            addNewProject={this.addProjektVertretung}
                            removeProject={this.removeProjektVertretung}
                            onProjectChange={this.onProjektVertretungProjectChange}
                            onVertretungChange={this.onProjektVertretungVertretungChange}
                            disabled={!this.canUrlaubsantragBeSaved()}
                        />
                        <UrlaubsantragGenehmigungsVerantwortliche
                            ref={this.urlaubsantragGenehmigungsVerantwortlicheEditor}
                            options={this.state.mitarbeitersForSelect}
                            values={this.state.urlaubsantrag.genehmigungsDaten}
                            onChange={this.onGenehmigungsverantwortlicherChange}
                            disabled={!this.canUrlaubsantragBeSaved()}
                        />
                        <UrlaubsantragKommentare
                            kommentar={this.state.urlaubsantrag.kommentar}
                            onChangeKommentar={this.setKommentar}
                            onSaveDraft={this.saveDraft}
                            onBeantragen={this.beantragen}
                            enableSubmit={this.canUrlaubsantragBeSaved()}
                            enableBeantragen={this.canUrlaubsantragBeBeantragt()}
                            onVerwerfen={this.verwerfen}
                            enableVerwerfen={this.canUrlaubsantragBeVerworfen()}
                            showStornieren={[URLAUBSANTRAG_STATUS.GENEHMIGT, URLAUBSANTRAG_STATUS.STORNIERT].includes(this.state.urlaubsantrag.status)}
                            enableStornieren={this.canUrlaubsantragBeStorniert()}
                            onStornieren={this.stornieren}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}
