import React, { Component } from 'react';
import MonthPicker from './MonthPicker';
import 'react-month-picker/css/month-picker.css';
import Select from 'react-select';
import { PdfButton, XlsxButton } from './Buttons';

export default class MonatUebersichtFilter extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="row">
                    <div className="col-xl-2">
                        <div className="align-items-center row">
                            <label className="col-xl-3 col-form-label">Start</label>
                            <div className="col-xl-7">
                                <MonthPicker minValue={2019} value={this.props.startDate} onChange={this.props.onStartDateChange} />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2">
                        <div className="align-items-center row">
                            <label className="col-xl-3 col-form-label">Ende</label>
                            <div className="col-xl-7">
                                <MonthPicker minValue={2019} value={this.props.endDate} onChange={this.props.onEndDateChange} />
                            </div>
                        </div>
                    </div>
                    {this.props.mitarbeiters.length > 1 && (
                        <div className="col-xl-6">
                            <div className="form-group mb-0 row">
                                <label className="col-xl-2 text-xl-center text-lg-left col-form-label">Mitarbeiter</label>
                                <div className="col-xl-10">
                                    <Select
                                        options={this.props.mitarbeiters}
                                        value={this.props.selectedMitarbeiters}
                                        onChange={this.props.onMitarbeiterChange}
                                        isMulti={true}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="col-auto ml-auto mt-3 mt-lg-3 mt-xl-0">
                        <PdfButton onClick={this.props.onPdfButtonClick} />
                        <XlsxButton onClick={this.props.onXlsxButtonClick} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
