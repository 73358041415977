import React from 'react';
import ReactDOM from 'react-dom';
import ApolloApp from './apollo';

import StandortWidget from './StandortWidget/';
import ZeiterfassungWidget from './Zeiterfassung/ZeiterfassungWidget';
import KalenderWidget from './Zeiterfassung/KalenderWidget';
import ProjektstundenWidget from './Zeiterfassung/ProjektstundenWidget';
import MonatsuebersichtWidget from './Zeiterfassung/MonatsuebersichtWidget';
import AdminWidget from './Zeiterfassung/AdminWidget';
import MitarbeiterStundenUebersichtWidget from './Zeiterfassung/MitarbeiterStundenUebersichtWidget';
import ProjektStundenUebersichtWidget from './Zeiterfassung/ProjektStundenUebersichtWidget';
import MonatStundenUebersichtWidget from './Zeiterfassung/MonatStundenUebersichtWidget';
import UrlaubsantragEditWidget from './Urlaubsantrag/UrlaubsantragEdit';
import UrlaubsantragViewWidget from './Urlaubsantrag/UrlaubsantragView';
import UrlaubsantraegeVerwalten from './Urlaubsantrag/UrlaubsantraegeVerwaltenWidget';
import MeineUrlaubsantraege from './Urlaubsantrag/MeineUrlaubsantraegeWidget';
import UrlaubsantragVertretungWidget from './Urlaubsantrag/UrlaubsantragVertretungWidget';
import UrlaubsantragUebersicht from './Urlaubsantrag/UrlaubsantragUebersicht';
import GenehmigtUrlaubsantragErfassenWidget from './Urlaubsantrag/GenehmigtUrlaubsantragErfassung';
import UrlaubJahresuebersicht from './Zeiterfassung/UrlaubJahresuebersichtWidget';
import BalkenUrlaubsuebersicht from './Urlaubsantrag/BalkenUrlaubsuebersichtWidget';
import StudentstundenUebersicht from './Zeiterfassung/StudentstundenUebersichtWidget.js';
import GeburtstageSlider from './GeburtstagesliderWidget/GeburtstageSlider';
import AufgabenListeDashboard from './Aufgaben/Lists';
import AllgemeineAufgabenList from './Aufgaben/AllgemeineAufgabenListe';
import ProjektAufgabenList from './Aufgaben/ProjektAufgabenListe';
import AufgabeErstellen from './Aufgaben/Aufgabe/AufgabeErstellen';

const renderWidget = (Component, domId) => {
    const domNode = document.getElementById(domId);
    if (!domNode) {
        return;
    }

    const dataset = { ...domNode.dataset };
    Object.keys(dataset).forEach(key => {
        try {
            dataset[key] = JSON.parse(dataset[key]);
        } catch (e) {}
    });

    ReactDOM.render(ApolloApp(Component, dataset), domNode);
};

renderWidget(StandortWidget, 'widget--standorte');
renderWidget(ZeiterfassungWidget, 'widget--zeiterfassung');
renderWidget(KalenderWidget, 'widget--kalender');
renderWidget(ProjektstundenWidget, 'widget--projektstunden');
renderWidget(MonatsuebersichtWidget, 'widget--monatsuebersicht');
renderWidget(AdminWidget, 'widget--admin');
renderWidget(MitarbeiterStundenUebersichtWidget, 'widget--stundenuebersicht-mitarbeiter');
renderWidget(ProjektStundenUebersichtWidget, 'widget--stundenuebersicht-projekt');
renderWidget(UrlaubsantraegeVerwalten, 'widget--urlaubverwalten--dashboard');
renderWidget(MeineUrlaubsantraege, 'widget--meinurlaub--dashboard');
renderWidget(UrlaubsantragVertretungWidget, 'widget--urlaubsvertretung--dashboard');
renderWidget(MonatStundenUebersichtWidget, 'widget--stundenuebersicht-monat');
renderWidget(UrlaubsantragEditWidget, 'widget--urlaubsantrag-edit');
renderWidget(UrlaubsantragViewWidget, 'widget--urlaubsantrag-view');
renderWidget(UrlaubsantragUebersicht, 'widget--urlaubsantrag-uebersicht');
renderWidget(GenehmigtUrlaubsantragErfassenWidget, 'widget--urlaubsantrag-genehmigt-erfassen');
renderWidget(UrlaubJahresuebersicht, 'widget--zeiterfassung-urlaub-uebersicht-jahresuebersicht');
renderWidget(BalkenUrlaubsuebersicht, 'widget--urlaubsantrag-uebersicht-balkenurlaubssuebersicht');
renderWidget(StudentstundenUebersicht, 'widget--zeiterfassung-zeiterfassung-uebersicht-studentstundenuebersicht');
renderWidget(GeburtstageSlider, 'widget--geburtstageslider--dashboard');
renderWidget(AufgabenListeDashboard, 'widget--aufgabenliste--dashboard');
renderWidget(AufgabeErstellen, 'widget--aufgabe-anlegen');
renderWidget(ProjektAufgabenList, 'widget--aufgabenliste--projekt');
renderWidget(AllgemeineAufgabenList, 'widget--aufgabenliste--allgemeine');
