import React from 'react';
import Layer from '../../../../../../application/public/images/icons/layers.svg';
import File from '../../../../../../application/public/images/icons/file.svg';

export const createBaumDataFromAllKnoten = (knoten, aktuellerKnoten, withCounter = false) => {
    const normalizeNode = (knoten, expanded) => {
        const filteredKnoten = omit(knoten, ['kinder']);

        return {
            key: knoten.id + '',
            title: withCounter && knoten.anzahlInhaltsaggregate !== 0 ? `${knoten.name} (${knoten.anzahlInhaltsaggregate})` : knoten.name,
            expanded: expanded,
            children: knoten.kinder ? knoten.kinder.map(child => normalizeNode(child, false)) : null,
            selectable: knoten.id !== aktuellerKnoten,
            icon: <img alt="layer-icon" src={knoten.kinder.length ? Layer : File} className="tree-knoten" />,
            ...filteredKnoten,
        };
    };

    return [normalizeNode(knoten, true)];
};

const omit = (obj, keys) => {
    return Object.fromEntries(Object.entries(obj).filter(([key]) => !keys.includes(key)));
};
