import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { InputField, ReactSelectAdapter, required, handleFormSubmitResult, transformItemsWithIdAndNameForReactSelect } from '../../formHelpers';
import { REACT_SELECT_INHALTSTYPEN } from '../WissenConstants';
import { convertInhaltsTypen, convertInhaltsTypenForSelect } from '../getInhaltsTyp';
import { convertBearbeiter } from '../../Aufgaben/Aufgabe/getAufgabeView';
import { wissenKnotenBearbeiten } from '../wissenApi';
import { normalizeKnoten } from './normalizeKnoten';

export default ({ knoten, toggleModal, updateKnoten, benutzer, inhaltsTypen, berechtigungsgruppen }) => {
    const [dropdownData] = useState({
        benutzer: convertBearbeiter(benutzer),
        inhaltsTypen,
        berechtigungsgruppen: transformItemsWithIdAndNameForReactSelect(berechtigungsgruppen),
    });

    const onSubmit = async values => {
        const normalizedKnoten = normalizeKnoten({
            ...values,
            icon: null,
            kinderErlaubt: values.kinderErlaubt ? values.kinderErlaubt : false,
            parent: knoten.parent,
            inhaltstypen: values.inhaltstypen ? convertInhaltsTypen(values.inhaltstypen) : [],
            ersteller: knoten.ersteller,
            moderatoren: values.moderatoren.map(moderator => ({ id: moderator.id, name: moderator.name })),
            label: values.label
                ? {
                      id: knoten.label ? knoten.label.id : null,
                      name: values.label,
                      ersteller: knoten.label ? knoten.label.ersteller : null,
                  }
                : null,
        });
        const result = await wissenKnotenBearbeiten(normalizedKnoten);
        if (handleFormSubmitResult(result, 'Knoten erfolgreich bearbeitet!')) {
            updateKnoten(result.alleKnoten);
        }
    };

    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{
                ...knoten,
                inhaltstypen: convertInhaltsTypenForSelect(knoten.inhaltstypen),
                label: knoten.label && knoten.label.name,
                moderatoren: convertBearbeiter(knoten.moderatoren),
                eingeschraenktAufBerechtigungsgruppen: transformItemsWithIdAndNameForReactSelect(knoten.eingeschraenktAufBerechtigungsgruppen),
            }}
            render={({ handleSubmit, pristine, submitting, invalid }) => (
                <form onSubmit={handleSubmit}>
                    <div className="container-fluid">
                        <div className="row">
                            <InputField label="Name">
                                <Field name="name" component="input" className="form-control" validate={required} autoFocus>
                                    {props => (
                                        <input
                                            autoFocus
                                            placeholder={props.meta.error && props.meta.touched ? props.meta.error : 'Name'}
                                            {...props.input}
                                            className={`form-control ${props.meta.error && props.meta.touched && 'font-weight-bold border-danger text-danger'}`}
                                        />
                                    )}
                                </Field>
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Inhaltstypen">
                                <Field
                                    name="inhaltstypen"
                                    placeholder="Bitte Wählen"
                                    component={ReactSelectAdapter}
                                    options={REACT_SELECT_INHALTSTYPEN}
                                    isMulti={true}
                                />
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Moderatoren">
                                <Field
                                    name="moderatoren"
                                    placeholder="Bitte wählen"
                                    component={ReactSelectAdapter}
                                    options={dropdownData.benutzer}
                                    isMulti={true}
                                />
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Berechtigungsgruppen">
                                <Field
                                    name="eingeschraenktAufBerechtigungsgruppen"
                                    placeholder="Bitte wählen"
                                    component={ReactSelectAdapter}
                                    options={dropdownData.berechtigungsgruppen}
                                    isMulti={true}
                                />
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Label">
                                <Field name="label" component="input" className="form-control" />
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Unterknoten möglich" className="d-flex">
                                <Field name="kinderErlaubt" className="form-control" type="checkbox">
                                    {props => <input {...props.input} type="checkbox" />}
                                </Field>
                            </InputField>
                        </div>
                    </div>
                    <hr className="w-100" />
                    <div className="modal-footer p-0 w-100 border-0">
                        <button type="submit" className="btn btn-primary" disabled={submitting || invalid} onClick={e => handleSubmit(e).then(toggleModal())}>
                            Speichern und Schliessen
                        </button>
                    </div>
                </form>
            )}
        />
    );
};
