import React, { useState } from 'react';
import { Field, Form } from 'react-final-form';
import { InputField, ReactSelectAdapter, required, handleFormSubmitResult, transformItemsWithIdAndNameForReactSelect } from '../../formHelpers';
import * as api from '../../api';
import { convertInhaltsTypen } from '../getInhaltsTyp';
import { REACT_SELECT_INHALTSTYPEN } from '../WissenConstants';
import { convertBearbeiter } from '../../Aufgaben/Aufgabe/getAufgabeView';
import { normalizeKnoten } from './normalizeKnoten';

export default ({ knotenParent, toggleModal, addNewKnotenToKnotenBaum, benutzer, inhaltsTypen, berechtigungsgruppen }) => {
    const [dropdownData] = useState({
        benutzer: convertBearbeiter(benutzer),
        inhaltsTypen,
        berechtigungsgruppen: transformItemsWithIdAndNameForReactSelect(berechtigungsgruppen),
    });

    const onSubmit = async values => {
        const normalizedParent = normalizeKnoten(knotenParent);

        const result = await api.postJson('/wissen/knoten/erzeugen', {
            ...values,
            position: normalizedParent.children.length + 1,
            kinderErlaubt: values.kinderErlaubt ? values.kinderErlaubt : false,
            parent: normalizedParent,
            inhaltstypen: values.inhaltstypen ? convertInhaltsTypen(values.inhaltstypen) : [],
            moderatoren: values.moderatoren ? values.moderatoren.map(moderator => ({ id: moderator.id, name: moderator.name })) : [],
            ersteller: normalizedParent.ersteller,
            label: values.label
                ? {
                      id: null,
                      name: values.label,
                      ersteller: null,
                  }
                : null,
        });
        if (handleFormSubmitResult(result, 'Knoten erfolgreich angelegt!')) {
            addNewKnotenToKnotenBaum(result.alleKnoten);
        }
    };

    return (
        <div className="container-fluid">
            <Form
                onSubmit={onSubmit}
                render={({ handleSubmit, submitting, pristine }) => (
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <InputField label="Name">
                                    <Field name="name" component="input" className="form-control" validate={required} autoFocus>
                                        {props => (
                                            <input
                                                autoFocus
                                                placeholder={props.meta.error && props.meta.touched ? props.meta.error : 'Name'}
                                                {...props.input}
                                                className={`form-control ${props.meta.error &&
                                                    props.meta.touched &&
                                                    'font-weight-bold border-danger text-danger'}`}
                                            />
                                        )}
                                    </Field>
                                </InputField>
                            </div>
                        </div>
                        <div className="row">
                            <InputField label="Inhaltstypen">
                                <Field
                                    name="inhaltstypen"
                                    placeholder="Bitte Wählen"
                                    component={ReactSelectAdapter}
                                    options={REACT_SELECT_INHALTSTYPEN}
                                    isMulti={true}
                                />
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Moderatoren">
                                <Field
                                    name="moderatoren"
                                    placeholder="Bitte wählen"
                                    component={ReactSelectAdapter}
                                    options={dropdownData.benutzer}
                                    isMulti={true}
                                />
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Berechtigungsgruppen">
                                <Field
                                    name="eingeschraenktAufBerechtigungsgruppen"
                                    placeholder="Bitte wählen"
                                    component={ReactSelectAdapter}
                                    options={dropdownData.berechtigungsgruppen}
                                    isMulti={true}
                                />
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Label">
                                <Field name="label" component="input" className="form-control" />
                            </InputField>
                        </div>
                        <div className="row">
                            <InputField label="Unterknoten möglich ?" className="d-flex">
                                <Field name="kinderErlaubt" className="form-control" type="checkbox">
                                    {props => <input {...props.input} type="checkbox" />}
                                </Field>
                            </InputField>
                        </div>
                        <div className="modal-footer p-0 w-100 border-0">
                            <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={submitting || pristine}
                                onClick={e => handleSubmit(e).then(toggleModal())}>
                                Speichern und Schliessen
                            </button>
                        </div>
                    </form>
                )}
            />
        </div>
    );
};
