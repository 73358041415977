import React, { useState } from 'react';
import Modal from '../../Modal/Modal';
import * as api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { handleFormSubmitResult } from '../../formHelpers';

export default ({ knoten, deleteKnoten }) => {
    const [modalOpen, toggleModal] = useState(false);

    const handleKnotenDelete = async () => {
        const result = await api.deleteAction(`/wissen/knoten/${knoten.id}/loeschen`);
        if (handleFormSubmitResult(result, 'Knoten erfolgreich gelöscht!')) {
            toggleModal(!modalOpen);
            deleteKnoten();
        }
    };

    return (
        <React.Fragment>
            <button className="btn btn-sm btn-danger" onClick={() => toggleModal(!modalOpen)}>
                <FontAwesomeIcon icon="trash" />
            </button>
            {modalOpen && (
                <Modal
                    onClose={() => toggleModal(!modalOpen)}
                    size="md"
                    title="Knoten löschen"
                    canClose={true}
                    footer={
                        <div className="btn-group">
                            <div className="btn btn-outline-secondary" onClick={() => toggleModal(!modalOpen)}>
                                Abbrechen
                            </div>
                            <div className="btn btn-danger" onClick={() => handleKnotenDelete()}>
                                Ja
                            </div>
                        </div>
                    }>
                    <div className="d-flex align-items-center justify-content-center">
                        <h3>
                            <span className="font-weight-bold">{knoten.name}</span> wirklich löschen ?
                        </h3>
                    </div>
                </Modal>
            )}
        </React.Fragment>
    );
};
