import React, { Component } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import * as api from '../api';
import { gql } from 'apollo-boost';
import { Query, Mutation } from 'react-apollo';
import { LPHS } from '../constants';
import Header from './ZeiterfassungWidget/Header';
import ProjektEdit from './ZeiterfassungWidget/ProjektEdit';
import EditableTimeValue, {
    MODE__COMPLETE_TO_HOURS,
    MODE__COMPLETE_TO_MINUTES,
    MODE__COMPLETE_TO_EDUCATED_GUESS,
} from './ZeiterfassungWidget/EditableTimeValue';
import EditableValue from './ZeiterfassungWidget/EditableValue';
import key from 'keymaster';
import stack from '../asyncStack';

key.filter = () => true;

class ZeiterfassungWidget extends Component {
    state = {
        fetching: true,
        data: null,
        user: null,
        week: null,
        editable: null,
        // set to projectId to edit, or 0 to add new project
        editProjekt: null,
        open: false,
    };

    constructor(props) {
        super(props);
        this.references = {};
    }

    componentDidMount() {
        this.loadWeek(this.props.user, this.props.week);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.week !== this.state.week || nextProps.user !== this.state.user) {
            this.loadWeek(nextProps.user, nextProps.week);
        }
    }

    async loadWeek(user, week) {
        this.setState({
            fetching: true,
        });

        const json = await api.getJson(`/zeiterfassung/detail-week/${user}/${week}/`);

        this.setState({
            data: json.calendarWeek,
            userSollStunden: json.mitarbeiterSollStundenOfWeekDays,
            user,
            week,
            fetching: false,
            // reset edit projekt (done after updating, but also when feching a new week)
            editProjekt: null,
        });
    }

    getProjectSum(dayOffset) {
        const sum = this.getProjects()
            .map(project => project.sums[dayOffset])
            .reduce((prev, curr) => prev + curr, 0);

        return `${Math.floor(sum / 60)}:${numeral(sum % 60).format('00')}`;
    }

    getDaySum(dayOffSet) {
        const { data } = this.state;

        if (null === data) {
            return 0;
        }

        // get the day of the input field, if undefined return empty strings
        let day = data.days[dayOffSet] || { von: '', bis: '', pause: '', krank: '', urlaub: '' };

        // if no von / bis value is given return empty string
        if (day.von.length === 0 || day.bis.length === 0) {
            return '';
        }

        const getMinutesFromTimeString = timeString => {
            if (0 === timeString.length) {
                return 0;
            }
            const [vonHours, vonMins] = moment(timeString)
                .format('HH:mm')
                .split(/:/)
                .map(val => parseInt(val));
            return vonHours * 60 + vonMins;
        };

        // get the difference between von / bis and format
        let sum = getMinutesFromTimeString(day.bis) - getMinutesFromTimeString(day.von);
        // if pause value is given, subtract from von / bis range
        sum -= getMinutesFromTimeString(day.pause);
        // if krank value is given, subtract from von / bis range
        sum += getMinutesFromTimeString(day.krank);
        // if urlaub value is given, subtract from von / bis range
        sum += getMinutesFromTimeString(day.urlaub);

        // set summation of day
        return `${numeral(Math.trunc(sum / 60)).format('0')}:${numeral(sum % 60).format('00')}`;
    }

    getProjects() {
        const { data } = this.state;

        if (null === data) {
            return [];
        }

        return data.projects.map(project => {
            const lphKeys = Object.keys(project.zeiten);
            const lphs = lphKeys.map(index => LPHS[index]);

            const zeiten = [];
            let sums = [];

            for (var i = 0; i < 7; i++) {
                zeiten.push(
                    lphKeys.map(lph => ({
                        lph,
                        zeitString: project.zeiten[lph][i],
                    }))
                );
                sums.push(
                    lphKeys
                        // fetch values for each lph in minutes
                        .map(lph => {
                            const zeit = project.zeiten[lph][i];
                            if (!zeit) {
                                return 0;
                            }

                            return moment(zeit).hours() * 60 + moment(zeit).minutes();
                        })
                        // sum up all the lphs
                        .reduce((prev, curr) => prev + curr, 0)
                );
            }

            return {
                ...project,
                lphs,
                zeiten,
                sums,
            };
        });
    }

    setDayValue(update, dayOffset, field, value) {
        const deferredSetDayValue = async () => {
            this.setState({
                fetching: true,
            });

            const { data } = this.state;
            const { user } = this.props;

            // determine the day
            const day = moment(data.firstDayIso)
                .add(dayOffset, 'days')
                .format('YYYY-MM-DD');

            const content = await api.postJson(`/zeiterfassung/set-tag-value/${user}/${day}/${field}/`, {
                value: null === value ? '' : value.format(),
            });

            update();

            // merge in new content to
            data.days[dayOffset] = {
                ...data.days[dayOffset],
                ...content,
            };

            this.setState({
                data,
                fetching: false,
            });
        };

        stack.push(deferredSetDayValue);
    }

    setDayComment(update, dayOffset, value) {
        const deferredSetDayComment = async () => {
            this.setState({
                fetching: true,
            });

            const { data } = this.state;
            const { user } = this.props;

            // determine the day
            const day = moment(data.firstDayIso)
                .add(dayOffset, 'days')
                .format('YYYY-MM-DD');

            const content = await api.postJson(`/zeiterfassung/set-tag-comment/${user}/${day}/`, {
                value,
            });

            // merge in new content to
            data.days[dayOffset] = {
                ...data.days[dayOffset],
                ...content,
            };

            this.setState({
                data,
                fetching: false,
            });
        };

        stack.push(deferredSetDayComment);
    }

    setProjectValue(update, projektIndex, lph, dayOffset, value) {
        const deferredSetProjectValue = async () => {
            this.setState({
                fetching: true,
            });

            const { data } = this.state;
            const { user } = this.props;

            // determine the day
            const day = moment(data.firstDayIso)
                .add(dayOffset, 'days')
                .format('YYYY-MM-DD');
            const projektId = data.projects[projektIndex].id;

            // /set-projekt-value/{mitarbeiterId}/{projektId}/{lph}/{day}/
            const content = await api.postJson(`/zeiterfassung/set-projekt-value/${user}/${projektId}/${lph}/${day}/`, {
                value: null === value ? '' : value.format(),
            });

            update();

            // merge in new content to
            data.projects[projektIndex].zeiten[lph][dayOffset] = content.time;
            data.days[dayOffset].vollstaendig = content.vollstaendig;

            this.setState({
                data,
                fetching: false,
            });
        };

        stack.push(deferredSetProjectValue);
    }

    projectDisabled = dayOffset => {
        return !this.state.data.days[dayOffset].hasKonditionen;
    };

    mapDayOffsetToDayName = dayOffset => {
        switch (dayOffset) {
            case 0:
                return 'MO';
            case 1:
                return 'DI';
            case 2:
                return 'MI';
            case 3:
                return 'DO';
            case 4:
                return 'FR';
            case 5:
                return 'SA';
            case 6:
                return 'SO';
        }

        return '?';
    };

    mapDayOffsetToDate = dayOffset => {
        const format = 'DD.MM.YYYY';
        return moment(this.state.data.firstDay, format)
            .add(parseInt(dayOffset, 10), 'days')
            .format(format);
    };

    render() {
        const { fetching, data, userSollStunden } = this.state;

        if (fetching && null === data) {
            return <div>Loading</div>;
        }

        /**
         * Requirement: The tab indices of the input fields must allow for tabbing from "Krank" to the first project,
         * and then through all projects for that day, before switching to the "von" field of the next day. A single
         * project may also have multiple rows, one for each Leistungsphase (lph). To implement this, we encode the tab
         * indices according to the following schema:
         * - every day has a range of tab indices; these ranges are sequential and do not overlap
         * - individual von...Krank and project fields for a single day have sequential sub-ranges within the range
         *   assigned to that day
         * - indices for the first day are in the range of 100101-199999 (the von field for the first day has index
         *   100101, bis has 100201 etc, the first lph of the first project has 100601, the second lph 100602, the first
         *   lph of the second project has 100701, and so on)
         * - indices for the second day are in the range of 200101-299999, and so on
         *
         * Summary: days are encoded in the first digit, projects in the second to fourth digit, and Leistungsphases in
         * the fifth and sixth digit. This schema allows for up to 994 projects each with up to 99 Leistungsphases before
         * the tab indices stop working properly (999 available values for the project digits, minus 5 values used to
         * represent the von...Krank fields).
         */
        const i = val => parseInt(val, 10);
        // both dayIndex and projectIndex are fed from array.map so they start at 0; lph start at 1
        const getTabIndexForDayField = (dayIndex, fieldIndex) => (i(dayIndex) + 1) * 100000 + i(fieldIndex) * 100 + 1;
        const getTabIndexForProjectField = (dayIndex, projectIndex, lph) => (i(dayIndex) + 1) * 100000 + (6 + i(projectIndex)) * 100 + i(lph);
        // the extra 6 above is added because 100101-100501 are taken up by the various "day" fields

        // Requirement: tab to next element by pressing enter
        const tabToNext = e => {
            // keyCode 13 = enter
            if (e.keyCode === 13) {
                e.preventDefault();

                const currentIndex = i(e.target.getAttribute('tabindex'));
                const nextLphIndex = currentIndex + 1;
                const nextProjectIndex = Math.floor((currentIndex + 100) / 100) * 100 + 1;
                const nextDayIndex = Math.floor((currentIndex + 100000) / 100000) * 100000 + 101;

                const nextElement =
                    // first, we try if there is another lph row
                    document.querySelector('[tabindex="' + nextLphIndex + '"]') ||
                    // then, we try if there is another project/von...Krank row
                    document.querySelector('[tabindex="' + nextProjectIndex + '"]') ||
                    // finally, we try if there is another day after this one
                    document.querySelector('[tabindex="' + nextDayIndex + '"]');
                // gotta check if the next day selector found something to avoid errors on the last day
                if (nextElement !== null) {
                    nextElement.focus();
                }
            }
        };

        return (
            <Mutation mutation={UPDATE_WIDGET}>
                {update => (
                    <div className="card zeiterfassung--widget">
                        <div className="card-body">
                            <Header data={data} fetching={fetching} />
                        </div>
                        <table className="table table-striped table-responsive-xl table-small mb-0">
                            <thead>
                                <tr>
                                    <th>PROJEKT</th>
                                    <th />
                                    {data.days.map((day, dayOffset) => (
                                        <th
                                            key={dayOffset}
                                            className={`text-center ${!day.vollstaendig ? 'incomplete-day' : ''} ${day.isHoliday ? 'holiday' : ''}`}>
                                            <div className="d-flex align-items-center justify-content-center">
                                                <span>{this.mapDayOffsetToDayName(dayOffset)}</span>
                                                {userSollStunden && (
                                                    <span className="small ml-2">({userSollStunden[this.mapDayOffsetToDayName(dayOffset)]})</span>
                                                )}
                                            </div>
                                            <div>{this.mapDayOffsetToDate(dayOffset)}</div>
                                        </th>
                                    ))}
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="bg-day-values">
                                    <td />
                                    <td className="zeiterfassung-placeholder-td zeiterfassung--widget--legend text-center">
                                        <div>von</div>
                                        <div>bis</div>
                                        <div>Pause</div>
                                        <div>Urlaub</div>
                                        <div>Krank</div>
                                        <div>Kommentar</div>
                                        <div>
                                            &sum; Tag
                                            <br />
                                            &sum; Projekt
                                        </div>
                                    </td>
                                    {data.days.map((day, dayOffset) => (
                                        <td key={dayOffset}>
                                            <EditableTimeValue
                                                mode={MODE__COMPLETE_TO_HOURS}
                                                roundMinutes={true}
                                                timeString={day.von}
                                                onChange={v => this.setDayValue(update, dayOffset, 'von', v)}
                                                tabIndex={getTabIndexForDayField(dayOffset, 1)}
                                                onKeyUp={e => tabToNext(e)}
                                                disabled={!day.hasKonditionen}
                                            />
                                            <EditableTimeValue
                                                mode={MODE__COMPLETE_TO_HOURS}
                                                roundMinutes={true}
                                                timeString={day.bis}
                                                onChange={v => this.setDayValue(update, dayOffset, 'bis', v)}
                                                tabIndex={getTabIndexForDayField(dayOffset, 2)}
                                                onKeyUp={e => tabToNext(e)}
                                                disabled={!day.hasKonditionen}
                                            />
                                            <EditableTimeValue
                                                mode={MODE__COMPLETE_TO_EDUCATED_GUESS}
                                                roundMinutes={true}
                                                timeString={day.pause}
                                                onChange={v => this.setDayValue(update, dayOffset, 'pause', v)}
                                                tabIndex={getTabIndexForDayField(dayOffset, 3)}
                                                onKeyUp={e => tabToNext(e)}
                                                disabled={!day.hasKonditionen}
                                            />
                                            <EditableTimeValue
                                                mode={MODE__COMPLETE_TO_HOURS}
                                                timeString={day.urlaub}
                                                onChange={v => this.setDayValue(update, dayOffset, 'urlaub', v)}
                                                tabIndex={getTabIndexForDayField(dayOffset, 4)}
                                                onKeyUp={e => tabToNext(e)}
                                                disabled={true}
                                            />
                                            <EditableTimeValue
                                                mode={MODE__COMPLETE_TO_HOURS}
                                                timeString={day.krank}
                                                onChange={v => this.setDayValue(update, dayOffset, 'krank', v)}
                                                tabIndex={getTabIndexForDayField(dayOffset, 5)}
                                                onKeyUp={e => tabToNext(e)}
                                                disabled={!day.hasKonditionen}
                                            />
                                            <EditableValue
                                                value={day.kommentar}
                                                onChange={v => this.setDayComment(update, dayOffset, v)}
                                                tabIndex={getTabIndexForDayField(dayOffset, 6)}
                                                onKeyUp={e => tabToNext(e)}
                                                disabled={!day.hasKonditionen}
                                            />
                                            <div>
                                                <input className="form-control form-control-zeit-input" value={this.getDaySum(dayOffset)} disabled />
                                                <input className="form-control form-control-zeit-input" value={this.getProjectSum(dayOffset)} disabled />
                                            </div>
                                        </td>
                                    ))}
                                    <td />
                                </tr>
                                {this.getProjects().map((project, projectIndex) => (
                                    <tr key={project.name}>
                                        <th className="project-name-col">
                                            <a href={`/projekt/${project.id}/detail/`}>{project.name}</a>
                                        </th>
                                        <td className="text-center zeiterfassung--widget--legend">
                                            {!project.internal &&
                                                project.lphs.map(lph => (
                                                    <div key={lph}>
                                                        <nobr>{lph}</nobr>
                                                    </div>
                                                ))}
                                        </td>
                                        {project.zeiten.map((zeit, dayOffset) => (
                                            <td key={dayOffset} className="text-center zeiterfassung">
                                                {zeit.map(zeitObj => {
                                                    const id = `${project.id}_o${dayOffset}_${zeitObj.lph}`;
                                                    return (
                                                        <EditableTimeValue
                                                            mode={MODE__COMPLETE_TO_EDUCATED_GUESS}
                                                            roundMinutes={true}
                                                            key={id}
                                                            timeString={zeitObj.zeitString}
                                                            onChange={v => this.setProjectValue(update, projectIndex, zeitObj.lph, dayOffset, v)}
                                                            tabIndex={getTabIndexForProjectField(dayOffset, projectIndex, zeitObj.lph)}
                                                            onKeyUp={e => tabToNext(e)}
                                                            disabled={this.projectDisabled(dayOffset)}
                                                        />
                                                    );
                                                })}
                                            </td>
                                        ))}
                                        <td className="text-center edit-project-col">
                                            <button className="btn btn-sm btn-link text-black-50" onClick={() => this.setState({ editProjekt: project.id })}>
                                                <i className="fas fa-edit" />
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="card-body border-top-1">
                            <ProjektEdit
                                projektId={this.state.editProjekt}
                                user={this.props.user}
                                onUpdate={() => this.loadWeek(this.props.user, data.current)}
                            />
                        </div>
                    </div>
                )}
            </Mutation>
        );
    }
}

export default props => (
    <Query query={GET_WEEK}>
        {({ loading, error, data }) => {
            if (loading) return <div>Loading...</div>;
            if (error) return <div>Error :(</div>;

            return <ZeiterfassungWidget week={data.zeiterfassungWidget.week} user={data.user} />;
        }}
    </Query>
);

const GET_WEEK = gql`
    query {
        user @client
        zeiterfassungWidget @client {
            week
        }
    }
`;

const UPDATE_WIDGET = gql`
    mutation update {
        updateZeiterfassungWidget @client
    }
`;
