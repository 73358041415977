import React, { useState } from 'react';
import * as api from '../../api';
import SortableTree from 'react-sortable-tree';
import KnotenErzeugen from '../Knoten/KnotenErzeugen';
import KnotenEdit from '../Knoten/KnotenEdit';
import KnotenDelete from '../Knoten/KnotenDelete';
import { toast } from 'react-toastify';
import { createBaumDataFromAllKnoten } from '../Knoten/createBaumDataFromAllKnoten';
import { normalizeKnoten } from '../Knoten/normalizeKnoten';

export default initialProps => {
    const [data, setData] = useState(createBaumDataFromAllKnoten(initialProps.knoten, null, true));

    const handleKnotenSortieren = async newParentKnoten => {
        const result = await api.postJson(`/wissen/knoten/sortieren`, {
            sortierung: newParentKnoten.children.map((childKnoten, index) => ({
                id: childKnoten.id,
                position: index,
            })),
        });

        if (result.error) {
            toast.error(result.error);
        } else if (result.statusCode && result.statusCode !== 200) {
            toast.error('Es ist ein unerwarteter Fehler aufgetreten');
        }
    };

    const handleKnotenVerschieben = async (movedKnoten, newParentKnoten) => {
        const normalizedKnoten = normalizeKnoten(movedKnoten);
        const normalizedParent = normalizeKnoten(newParentKnoten);

        const result = await api.postJson(`/wissen/knoten/verschieben`, {
            ...normalizedKnoten,
            position: normalizedParent.children.indexOf(normalizedKnoten),
            parent: {
                ...normalizedParent,
                kinder: normalizedParent.children,
            },
        });

        if (result.error) {
            toast.error(result.error);
        } else if (result.statusCode && result.statusCode !== 200) {
            toast.error('Es ist ein unerwarteter Fehler aufgetreten');
        }

        await handleKnotenSortieren(newParentKnoten);
    };

    const addNewKnotenToKnotenBaum = allData => {
        setData(createBaumDataFromAllKnoten(allData, null, true));
    };

    const updateKnoten = allData => {
        setData(createBaumDataFromAllKnoten(allData, null, true));
    };

    const deleteKnoten = deletedKnoten => {
        const removeNodeById = (nodes, idToRemove) => {
            return nodes.filter(node => {
                if (node.id === idToRemove) {
                    return false;
                }
                if (node.children && node.children.length > 0) {
                    node.children = removeNodeById(node.children, idToRemove);
                }
                return true;
            });
        };

        setData(removeNodeById(data, deletedKnoten.id));
    };

    const findParentById = (nodes, idToFind) => {
        for (const node of nodes) {
            if (!node.kinder || node.kinder.length === 0) {
                continue;
            }

            const child = node.kinder.find(childNode => childNode.id === idToFind);
            if (child) {
                return node;
            }

            const parent = findParentById(node.kinder, idToFind);
            if (parent) {
                return parent;
            }
        }
        return null;
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body" style={{ height: 800 }}>
                            {data.length === 0 && <h3>Lade daten ...</h3>}
                            <SortableTree
                                treeData={data}
                                onChange={treeData => setData(treeData)}
                                canDrag={node => node.parentNode !== null}
                                canDrop={node => node.nextParent !== null}
                                onMoveNode={node => {
                                    const parentNode = findParentById([initialProps.knoten], node.node.id);
                                    if (parentNode && parentNode.id === node.nextParentNode.id) {
                                        handleKnotenSortieren(node.nextParentNode);
                                    } else {
                                        handleKnotenVerschieben(node.node, node.nextParentNode);
                                    }
                                }}
                                generateNodeProps={data => ({
                                    buttons: [
                                        <div className="h-100 w-100 d-flex justify-content-center align-items-center mr-3">
                                            <span>Inhaltselemente ({data.node.wikis.length + data.node.fragen.length + data.node.dokumente.length})</span>
                                        </div>,
                                        data.node.kinderErlaubt && (
                                            <KnotenErzeugen
                                                knotenParent={data.node}
                                                addNewKnotenToKnotenBaum={alleKnoten => addNewKnotenToKnotenBaum(alleKnoten)}
                                                inhaltsTypen={initialProps.inhaltsTypen}
                                                benutzer={initialProps.benutzer}
                                                berechtigungsgruppen={initialProps.berechtigungsgruppen}
                                            />
                                        ),
                                        <KnotenEdit
                                            updateKnoten={alleKnoten => updateKnoten(alleKnoten)}
                                            knoten={data.node}
                                            inhaltsTypen={initialProps.inhaltsTypen}
                                            benutzer={initialProps.benutzer}
                                            berechtigungsgruppen={initialProps.berechtigungsgruppen}
                                        />,
                                        data.treeIndex !== 0 && data.node.children.length === 0 && (
                                            <KnotenDelete deleteKnoten={() => deleteKnoten(data.node)} knoten={data.node} />
                                        ),
                                    ],
                                })}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
